import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ActiveAuthenticationStateService } from '@auth-n/state/active-authentication.state-service';
import { LoggingService } from '@logging/logging.service';
import { Observable } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { ActiveSecurityContextStateService } from '../active-security/active-security-context.state-service';
import { ApplicationPermission, BureauProfilePermission } from '@security/users/models/user';

export interface BureauAuthorisedRouteGuardData {
  anyBureauProfilePermissions: BureauProfilePermission[];
  anyApplicationPermissions: ApplicationPermission[];
}

/**
 * Route guard to ensure there's a valid logged-in user having the required permission to access this route.
 */
@Injectable({ providedIn: 'root' })
export class BureauAuthorisedRouteGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationState: ActiveAuthenticationStateService,
    private activeSecurity: ActiveSecurityContextStateService,
    private logger: LoggingService
  ) {}

  /**
   * Implementation of {@link https://angular.io/api/router/CanActivate|CanActivate}
   * This function will not resolve until the user's role have been successfully fetched post-login.
   *
   * The required role should be passed in as {@link https://angular.io/api/router/Data|Data} config,
   * with {@link BureauAuthorisedRouteGuardData}, otherwise an error will be thrown.
   */
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    this.logger.trace('BUREAU AUTHORISED ROUTE GUARD: authorising: ', next);
    return this.activeSecurity.fetchingActiveUser$.pipe(
      tap((fetchingActiveUser) => this.logger.trace('AUTHORISED BUREAU ROUTE GUARD: fetchingActiveUser?', fetchingActiveUser)),
      filter((fetchingActiveUser) => fetchingActiveUser === false),
      take(1),
      map(() => {
        if (!this.authenticationState.isAuthenticated) {
          this.logger.trace('BUREAU AUTHORISED ROUTE GUARD: isAuthenticated?', false);
          return false;
        }

        let currentRoute: ActivatedRouteSnapshot | null = next;
        let anyBureauProfilePermissions: BureauProfilePermission[] = currentRoute.data['anyBureauProfilePermissions'];

        if (!anyBureauProfilePermissions.length)
          throw new Error(
            `Route Guard activated without specifying the required permission(s). Add the 'anyBureauProfilePermissionsData' key and permission to the route's config.`
          );

        let anyApplicationPermissions: ApplicationPermission[] = currentRoute.data['anyApplicationPermissions'] || [];
        anyApplicationPermissions.push('IsCintraSupport');

        if (this.activeSecurity.hasApplicationAuthorityToOneOf(anyApplicationPermissions)) {
          this.logger.trace('BUREAU AUTHORISED ROUTE GUARD: user has application authority');
          return true;
        }

        const authorised = this.activeSecurity.hasBureauProfileAuthorityToOneOf(anyBureauProfilePermissions);

        this.logger.trace('BUREAU AUTHORISED ROUTE GUARD: authorised to?', {
          anyBureauProfilePermissions: anyBureauProfilePermissions,
          authorised
        });

        if (!authorised) {
          if (this.activeSecurity.canAccessCintraBureau()) {
            return this.router.parseUrl(this.activeSecurity.bureauHomeRoute());
          }
          return this.router.parseUrl('forbidden');
        } else {
          return true;
        }
      })
    );
  }
}
